import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const BackButtonStyle = styled.span`
    position: fixed;
    left: 20px; 
    top: 20px;
    
    svg { width:16px; height: 25px; margin-right: 5px; }

    a, 
    a:active, 
    a:focus, 
    a:visited { 
        font-size: 20px;
        color: #ff7600;
        text-decoration: none; 
        width: 60px;

        display: flex;
        align-items: center;
    }
`;

const BackButton: React.FC<any> = (props: any) => {
    return (
        <BackButtonStyle>
            <Link to='/'>
                <svg
                    aria-hidden="true" focusable="false"
                    className="svg-inline--fa fa-chevron-left fa-w-10" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                >
                    <path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
                </svg>
                Back
            </Link>
        </BackButtonStyle>);
}

const HeaderWrapper = styled.div`
img {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
    user-select: none;
}
h1 { 
    text-align: center;
    color: #46424a;
    font-size: 2.5em;
    font-weight: 800;
    margin-bottom: 0.33em;
}
`;

export const Header: React.FC<any> = () => { 
    const router = useLocation();
    const needBack = (router.pathname !== '/');
    return ( 
        <HeaderWrapper>
            <img src='images/bk.png' alt='' />
            <h1>
                { needBack ? <BackButton />: null }
                Recruitment ROI calculator
            </h1>
        </HeaderWrapper>
    );
} 

const FooterWrapper = styled.div`
margin-top: auto; 
background-color: #46424a;
color: white;

.cont { 
    max-width: 885px;
    margin: 0px auto;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: left;
}
@media(max-width:885px) {
    .cont { 
        padding: 40px;
    }
}

.cont .columns {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 50px;

    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    max-width: 885px;
}
.cont .column {
    font-size: 0.75em;
    line-height: 1.5em;
    width: 300px;
}
.cont .column strong {
    display: inline-block;
    width: 45px;
}
hr {
    border-style: solid;
    border-bottom: 0px;
    color: #c8c8ca;
}
.cont .copyright {
    font-size: 0.75em;
    line-height: 1.5em;
}
.cont .social {
    float: right;
    padding-top: 75px;
}
@media(max-width: 885px){
    .cont .social { 
        padding-top: 10px;
    }
}
`;

export const Footer: React.FC<any> = () => { 
    return ( 
        <FooterWrapper>
            <div className="cont">
                <div className="social">
                    <a 
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/mdm-consultants-ltd"
                    >
                        <img src="images/linked-in.png" alt="Linked In" />
                    </a>
                </div>
                <img src="images/footer-logo.png" alt="MDM consultants" />
                <div className="columns">
                    <div className="column">
                        1 Rushmills<br />
                        Bedford Road,<br />
                        Northampton,<br />
                        Northamptonshire,<br />
                        NN4 7YB
                    </div>
                    <div className="column">
                        <strong>Tel:</strong>
                        0203 011 0123<br />
                        <strong>Fax:</strong> 
                        0203 011 0137<br />
                        <strong>Email:</strong> 
                        info@mdmconsultants.co.uk
                    </div>
                </div>
                <hr />
                <div className="copyright">
                    &copy; 2020 MDM Consultants |  
                    All rights Reserved |
                    Powered by Workhouse
                </div>
            </div>
        </FooterWrapper>
    );
} 

